import React from "react";


const SubscriptionManagement = () => {

  return (
    <div>
      <h2>Subscription Management Settings</h2>

    </div>
  )
}

export default SubscriptionManagement;